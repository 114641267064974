/* eslint-disable react/prop-types */
import React, { useEffect, useCallback, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { mediaByIndex } from '../img-carousel';


const EmblaCarousel = ({ slides, options = { loop: true } }) => {
	const autoplay = useRef(
		Autoplay(
			{ delay: 1500, stopOnInteraction: false },
			(emblaRoot) => emblaRoot.parentElement
		)
	);

	const [emblaRef, emblaApi] = useEmblaCarousel(options, [autoplay.current]);

	const onSelect = useCallback(() => {
		if (!emblaApi) return;
	}, [emblaApi]);

	useEffect(() => {
		if (!emblaApi) return;
		onSelect();
		emblaApi.on('select', onSelect);
	}, [emblaApi, onSelect]);

	return (
		<div className="embla1">
			<div className="embla__viewport1" ref={emblaRef}>
				<div className="embla__container1">
					{slides.map((index) => (
						<div className="embla__slide1" key={index}>
							<div className="embla__slide__inner1">
								<img
									className="embla__slide__img1"
									src={mediaByIndex(index)}
									alt=""
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default EmblaCarousel;
