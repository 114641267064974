import {Routes, Route} from 'react-router-dom';
import React from 'react';
import Home from './Components/home';
import ErrorPage from './Components/errorPage';
import Contact from './Components/contact';
import Header from './Components/header';
import Footer from './Components/footer';
import Services from './Components/services';
/*import Newsletter from './Components/newsLetter';
import NewsletterPost from './Components/newsLetterPost';
*/
export default function App() {
	return (
		<div>
			<Header/>
			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/services" element={<Services />}/>
				{/*
				<Route path="/newsletter" element={<Newsletter />}/>
				<Route path="/newsletter/:slug"element={<NewsletterPost />} />
				*/}
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<ErrorPage />}/>
			</Routes>
			<Footer/>
		</div>
	);
}

