import * as React from 'react';
import { styled } from '@mui/material/styles';
import { createSvgIcon } from '@mui/material/utils';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';


const PlusIcon = createSvgIcon(
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
	>
		<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
	</svg>,
	'Plus',
);
  
const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={1} square {...props} />
))(() => ({
	'&':{
		borderBottom: '1px solid #81358A',
		marginLeft: '5%',
		width: '90%',
		boxShadow: 'none',
		bordeBottom: '1px solid #81358A',
	},
	'&:not(:last-child)': {
		borderBottom: '1px solid #81358A',
		marginLeft: '5%',
		width: '90%',
		boxShadow: 'none',
		bordeBottom: '1px solid #81358A',
	},
	'&:before': {
		display: 'none',
	},
}));


const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<PlusIcon sx={{ fontSize: '2.5rem', color: '#81358A'}} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:'white',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(0deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
		fontSize: '2rem'
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(3),
	borderTop: '1px solid #81358A;',
	backgroundColor: '#f4eef5'
}));


export default function CustomizedAccordions() {
	const [expanded, setExpanded] = React.useState('panel1');

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	const [t] = useTranslation('global');
	return (
		<div>
			<Accordion disableGutters TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
				<AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
					<Typography sx={{fontSize: '2rem'}}>{t('why.experience-tittle')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography sx={{fontSize: '2rem'}}>
						{t('why.experience-descr')}
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
				<AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
					<Typography sx={{fontSize: '2rem'}}>{t('why.technicians-tittle')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography sx={{fontSize: '2rem'}}>
						{t('why.technicians-descr')}
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
				<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
					<Typography sx={{fontSize: '2rem'}}>{t('why.agile-tittle')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography sx={{fontSize: '2rem'}}>
						{t('why.agile-descr')}
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
				<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
					<Typography sx={{fontSize: '2rem'}}>{t('why.focus-tittle')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography sx={{fontSize: '2rem'}}>
						{t('why.focus-descr')}
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
				<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
					<Typography sx={{fontSize: '2rem'}}>{t('why.results-tittle')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography sx={{fontSize: '2rem'}}>
						{t('why.results-descr')}
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
				<AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
					<Typography sx={{fontSize: '2rem'}}>{t('why.lowcost-tittle')}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography sx={{fontSize: '2rem'}}>
						{t('why.lowcost-descr')}
					</Typography>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}
