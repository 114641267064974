import React from 'react';
import { StyledEngineProvider } from '@mui/material/styles';
import Demo from './accordion';
import EmblaCarousel from './EmblaCarousel';
import { useTranslation } from 'react-i18next';


function Home(){
	const [t] = useTranslation('global');
	const SLIDE_COUNT = 21;
	const slides = Array.from(Array(SLIDE_COUNT).keys());
	return ( 
		<div>
			<section id="home" className="home-hero">
				<div className="home_container">
					<h1 className="home-title">{t('home.home-tittle')}</h1>
				</div>
			</section>
			<section id="servicios" className="service-hero">
				<div className="service_container">
					<h1 className="service-title">{t('services.our-services-tittle')}</h1>
					<h2 className="service-title-sub">{t('services.our-services-descr')}</h2>
				</div>
				<div className="service_main">
					<div className="service-cards1">
						<div className='service-card-header'>
							<img src="./imagenes/engranaje.svg" className="service_img" alt='Automation testing'/>
							<span className="service-img_title">{t('services.test-automation-tittle')}</span>
						</div>
						<span className="service-card_text">{t('services.test-automation-descr')}</span>
					</div>
					<div className="service-cards2">
						<div className='service-card-header'>
							<img src="./imagenes/notebook.svg" className="service_img" alt='Performance testing'/>
							<span className="service-img_title">{t('services.performance-tittle')}</span>
						</div>
						<span className="service-card_text">{t('services.performance-descr')}</span>
					</div>
					<div className="service-cards3">
						<div className='service-card-header'>
							<img src="./imagenes/consultoria.svg" className="service_img" alt='Consultoria de calidad'/>
							<span className="service-img_title">{t('services.consultoria-tittle')}</span>
						</div>
						<span className="service-card_text">{t('services.consultoria-descr')}</span>
					</div>
					<div className="service-cards4">
						<div className='service-card-header'>
							<img src="./imagenes/training.svg" className="service_img" alt='Entrenamientos QA'/>
							<span className="service-img_title">{t('services.training-tittle')}</span>
						</div>
						<span className="service-card_text">{t('services.training-descr')}</span>
					</div>
					<div className="service-cards5">
						<div className='service-card-header'>
							<img src="./imagenes/pipe.svg" className="service_img" alt='Countinous integration testing'/>
							<span className="service-img_title">{t('services.pipelines-tittle')}</span>
						</div>
						<span className="service-card_text">{t('services.pipelines-descr')}</span>
					</div>
				</div>
			</section>
			{/* <section id="proceso" className="comohacemos">
				<div className="proceso-main-container">
					<div className="proceso-container">
						<h1 className="proceso-title">{t('package.package-tittle')}</h1>
						<h2 className="proceso-sub">{t('package.package-descr')}</h2>
					</div>
					<div className="proceso_main">
						<div className="proceso-cards1">
							<span className="proceso-title-card">{t('package.package-start-title1')}<b>{t('package.package-start-title2')}</b></span>
							<span className="proceso-sub-card">{t('package.package-start-sub')}</span>
							<span className="proceso-card_text">{t('package.package-start-text')}</span>
							<span className="proceso-card-quality">{t('package.package-start-quality')}</span>
						</div>
						<div className="proceso-cards2">
							<span className="proceso-title-card">{t('package.package-impulse-title1')}<b>{t('package.package-impulse-title2')}</b></span>
							<span className="proceso-sub-card">{t('package.package-impulse-sub')}</span>
							<span className="proceso-card_text">{t('package.package-impulse-text')}</span>
							<span className="proceso-card-quality">{t('package.package-impulse-quality')}</span>
						</div>
						<div className="proceso-cards3">
							<span className="proceso-title-card">{t('package.package-transform-title1')}<b>{t('package.package-transform-title2')}</b></span>
							<span className="proceso-sub-card">{t('package.package-transform-sub')}</span>
							<span className="proceso-card_text">{t('package.package-transform-text')}</span>
							<span className="proceso-card-quality">{t('package.package-transform-quality')}</span>
						</div>
						<div className="proceso-cards4">
							<span className="proceso-title-card">{t('package.package-sinergy-title1')}<b>{t('package.package-sinergy-title2')}</b></span>
							<span className="proceso-sub-card4">{t('package.package-sinergy-sub')}</span>
							<span className="proceso-card_text4">{t('package.package-sinergy-text')}</span>
							<span className="proceso-card-quality">{t('package.package-sinergy-quality')}</span>
						</div>
						<div className="proceso-cards5">
							<span className="proceso-title-card5">{t('package.package-booster-title1')}<b>{t('package.package-booster-title2')}</b></span>
							<span className="proceso-sub-card">{t('package.package-booster-sub')}</span>
							<span className="proceso-card_text5">{t('package.package-booster-text')}</span>
							<span className="proceso-card-quality">{t('package.package-booster-quality')}</span>
						</div>
					</div>
				</div>
			</section> */}
			<section id="soluciones" className="porquetrabajar">
				<div className="soluciones-main">
					<h1 className="soluciones-title">{t('why.working-tittle')}</h1>
					<h2 className="soluciones-sub">{t('why.working-descr')}</h2>
				</div>
				<main>
					<StyledEngineProvider injectFirst>
						<Demo />
					</StyledEngineProvider>
				</main>
				<div className="nosotros_main">
					<div className="nosotros-cards1">
						<div className="nosotros_img1"/>
						{/* <div className="nosotros-card_text">
							<ul style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2rem'}}><svg style={{marginRight: '1rem'}} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 24 24" fill="none">
								<path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM18.2151 7.40811C17.825 7.01523 17.1899 7.01411 16.7984 7.40561L9.6 14.604L7.1991 12.2124C6.80836 11.8231 6.17625 11.8238 5.78626 12.2137L5.50711 12.4929C5.11658 12.8834 5.11658 13.5166 5.50711 13.9071L8.89289 17.2929C9.28342 17.6834 9.91658 17.6834 10.3071 17.2929L18.4954 9.10461C18.8849 8.71506 18.8861 8.08383 18.4979 7.6929L18.2151 7.40811Z" fill="#81358A"/>
							</svg><b>{t('nosotros.ul1-javi')}</b></ul>
							<ul style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2rem'}}><svg style={{marginRight: '1rem'}} xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 24 24" fill="none">
								<path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM18.2151 7.40811C17.825 7.01523 17.1899 7.01411 16.7984 7.40561L9.6 14.604L7.1991 12.2124C6.80836 11.8231 6.17625 11.8238 5.78626 12.2137L5.50711 12.4929C5.11658 12.8834 5.11658 13.5166 5.50711 13.9071L8.89289 17.2929C9.28342 17.6834 9.91658 17.6834 10.3071 17.2929L18.4954 9.10461C18.8849 8.71506 18.8861 8.08383 18.4979 7.6929L18.2151 7.40811Z" fill="#81358A"/>
							</svg>{t('nosotros.ul2-javi')}</ul>
							<li style={{marginLeft:'3rem', fontStyle: 'italic'}}><b>{t('nosotros.li1-javi')}</b></li>
							<li style={{marginLeft:'3rem', fontStyle: 'italic'}}><b>{t('nosotros.li2-javi')}</b></li>
							<li style={{marginLeft:'3rem', fontStyle: 'italic'}}><b>{t('nosotros.li3-javi')}</b></li>
							<li style={{marginLeft:'3rem', fontStyle: 'italic'}}><b>{t('nosotros.li4-javi')}</b></li>
							<ul style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2rem'}}><svg style={{marginRight: '1rem'}} xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 24 24" fill="none">
								<path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM18.2151 7.40811C17.825 7.01523 17.1899 7.01411 16.7984 7.40561L9.6 14.604L7.1991 12.2124C6.80836 11.8231 6.17625 11.8238 5.78626 12.2137L5.50711 12.4929C5.11658 12.8834 5.11658 13.5166 5.50711 13.9071L8.89289 17.2929C9.28342 17.6834 9.91658 17.6834 10.3071 17.2929L18.4954 9.10461C18.8849 8.71506 18.8861 8.08383 18.4979 7.6929L18.2151 7.40811Z" fill="#81358A"/>
							</svg>{t('nosotros.ul3-javi')}</ul>
						</div>
						<a href="https://www.linkedin.com/in/javierpicco/" target="_blank" rel="noopener noreferrer" className="nosotros-linkedin"></a> */}
					</div>
					<div className="nosotros-cards2">
						<div className="nosotros_img2"/>
						{/* <div className="nosotros-card_text">
							<ul style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2rem'}}><svg style={{marginRight: '1rem'}} xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 24 24" fill="none">
								<path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM18.2151 7.40811C17.825 7.01523 17.1899 7.01411 16.7984 7.40561L9.6 14.604L7.1991 12.2124C6.80836 11.8231 6.17625 11.8238 5.78626 12.2137L5.50711 12.4929C5.11658 12.8834 5.11658 13.5166 5.50711 13.9071L8.89289 17.2929C9.28342 17.6834 9.91658 17.6834 10.3071 17.2929L18.4954 9.10461C18.8849 8.71506 18.8861 8.08383 18.4979 7.6929L18.2151 7.40811Z" fill="#81358A"/>
							</svg><b>{t('nosotros.ul1-tomi')}</b></ul>
							<ul style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2rem'}}><svg style={{marginRight: '1rem'}} xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 24 24" fill="none">
								<path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM18.2151 7.40811C17.825 7.01523 17.1899 7.01411 16.7984 7.40561L9.6 14.604L7.1991 12.2124C6.80836 11.8231 6.17625 11.8238 5.78626 12.2137L5.50711 12.4929C5.11658 12.8834 5.11658 13.5166 5.50711 13.9071L8.89289 17.2929C9.28342 17.6834 9.91658 17.6834 10.3071 17.2929L18.4954 9.10461C18.8849 8.71506 18.8861 8.08383 18.4979 7.6929L18.2151 7.40811Z" fill="#81358A"/>
							</svg>{t('nosotros.ul2-tomi')}</ul>
							<li style={{marginLeft:'3rem', fontStyle: 'italic'}}><b>{t('nosotros.li1-javi')}</b></li>
							<li style={{marginLeft:'3rem', fontStyle: 'italic'}}><b>{t('nosotros.li4-javi')}</b></li>
							<ul style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '2rem'}}><svg style={{marginRight: '1rem'}} xmlns="http://www.w3.org/2000/svg" width="32" height="24" viewBox="0 0 24 24" fill="none">
								<path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM18.2151 7.40811C17.825 7.01523 17.1899 7.01411 16.7984 7.40561L9.6 14.604L7.1991 12.2124C6.80836 11.8231 6.17625 11.8238 5.78626 12.2137L5.50711 12.4929C5.11658 12.8834 5.11658 13.5166 5.50711 13.9071L8.89289 17.2929C9.28342 17.6834 9.91658 17.6834 10.3071 17.2929L18.4954 9.10461C18.8849 8.71506 18.8861 8.08383 18.4979 7.6929L18.2151 7.40811Z" fill="#81358A"/>
							</svg>{t('nosotros.ul3-javi')}</ul>
						</div>
						<a href="https://www.linkedin.com/in/tomasrioscba/" target="_blank" rel="noopener noreferrer" className="nosotros-linkedin"></a> */}
					</div>
				</div>
				<div className="clienteconfian">
					<div className="cliente-container">
						<span className="cliente-title">{t('client-trust.client-tittle')}</span>
					</div>
					<EmblaCarousel slides={slides}/>
				</div>
			</section>
		</div>
	);
}
export default Home;