/* eslint-disable indent */
import React from 'react';
import { useTranslation } from 'react-i18next';


const ServicesPage = () => {
    const [t] = useTranslation('global');
	return (
        <section id="proceso" className="comohacemos">
        <div className="proceso-main-container">
            <div className="proceso-container">
                <h1 className="proceso-title">{t('package.package-tittle')}</h1>
                <h2 className="proceso-sub">{t('package.package-descr')}</h2>
            </div>
            <div className="proceso_main">
                <div className="proceso-cards1">
                    <span className="proceso-title-card">{t('package.package-start-title1')}<b>{t('package.package-start-title2')}</b></span>
                    <span className="proceso-sub-card">{t('package.package-start-sub')}</span>
                    <span className="proceso-card_text">{t('package.package-start-text')}</span>
                    <span className="proceso-card-quality">{t('package.package-start-quality')}</span>
                </div>
                <div className="proceso-cards2">
                    <span className="proceso-title-card">{t('package.package-impulse-title1')}<b>{t('package.package-impulse-title2')}</b></span>
                    <span className="proceso-sub-card">{t('package.package-impulse-sub')}</span>
                    <span className="proceso-card_text">{t('package.package-impulse-text')}</span>
                    <span className="proceso-card-quality">{t('package.package-impulse-quality')}</span>
                </div>
                <div className="proceso-cards3">
                    <span className="proceso-title-card">{t('package.package-transform-title1')}<b>{t('package.package-transform-title2')}</b></span>
                    <span className="proceso-sub-card">{t('package.package-transform-sub')}</span>
                    <span className="proceso-card_text">{t('package.package-transform-text')}</span>
                    <span className="proceso-card-quality">{t('package.package-transform-quality')}</span>
                </div>
                <div className="proceso-cards4">
                    <span className="proceso-title-card">{t('package.package-sinergy-title1')}<b>{t('package.package-sinergy-title2')}</b></span>
                    <span className="proceso-sub-card4">{t('package.package-sinergy-sub')}</span>
                    <span className="proceso-card_text4">{t('package.package-sinergy-text')}</span>
                    <span className="proceso-card-quality">{t('package.package-sinergy-quality')}</span>
                </div>
                <div className="proceso-cards5">
                    <span className="proceso-title-card5">{t('package.package-booster-title1')}<b>{t('package.package-booster-title2')}</b></span>
                    <span className="proceso-sub-card">{t('package.package-booster-sub')}</span>
                    <span className="proceso-card_text5">{t('package.package-booster-text')}</span>
                    <span className="proceso-card-quality">{t('package.package-booster-quality')}</span>
                </div>
            </div>
        </div>
    </section>
	);
};

export default ServicesPage;