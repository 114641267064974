import media1 from '../img-carousel/liricus.svg';
import media2 from '../img-carousel/mauad.svg';
import media4 from '../img-carousel/smartfran.svg';
import media5 from '../img-carousel/inswitch.svg';
import media6 from '../img-carousel/vu.svg';
import media7 from '../img-carousel/entelai.svg';
import media8 from '../img-carousel/fabricarg.svg';
import media9 from '../img-carousel/aynilabs.svg';
import media10 from '../img-carousel/control.svg';
import media11 from '../img-carousel/outside.svg';
import media12 from '../img-carousel/spot.svg';
import media13 from '../img-carousel/tuxpan.svg';
import media14 from '../img-carousel/mindfactory.svg';
import media15 from '../img-carousel/otb.svg';

export const media = [media1, media2, media4, media5, media6, media7, media8, media9, media10, media11, media12, media13, media14, media15];
export const mediaByIndex = index => media[index % media.length];
