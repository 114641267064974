import { useTranslation } from 'react-i18next';
import React from 'react';

function Footer() {
	const [t] = useTranslation('global');
	return (
		<footer id="footer">
			<div className="content-section">
				<div className="row1">
					<img src="./imagenes/logofooter.svg" alt="kiuvi footer" className="logo-footer" />
					<p className="footer-description">{t('footer.description')}</p>
				</div>
				<div className="row2">
					<p className="contacto-footer">{t('footer.contact')}</p>
					<p className="contacto-tel">{t('footer.tel1')}</p>
					<p className="contacto-tel">{t('footer.tel2')}</p>
					<p className="footer-mail">{t('footer.email')}</p>
					<p className="contacto-text">{t('footer.ubication1')}</p>
					<p className="contacto-text">{t('footer.ubication2')}</p>
				</div>
				<div className="row4">
					<ul>
						<li className="footer_links">
							<a href="https://www.linkedin.com/company/kiuvi-sas/" target="_blank" rel="noreferrer">
								<img src="./imagenes/logolinkedin.svg" alt="" className="img-linkedin" style={{ width: '12rem' }} />
							</a>
						</li>
					</ul>
				</div>
				<div className="copy-footer">Copyright ® 2024 Company All rights reserved</div>
			</div>


		</footer>
	);
}

export default Footer;
