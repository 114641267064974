/* eslint-disable indent */
import React from 'react';
import logo from './logo.svg';

const ErrorPage = () => {
	return (
		<section className="error-hero">
			<a href='/' className="logocontenedor" >
				<img className="logoerrorpage" src={logo}></img>
			</a>
            <h2 className="title-error">404</h2>
            <h3 className="subtitle-error">Attention!</h3>
            <p className="descr-error">Looks like you are on unknown zone</p>
			<a href="/" className="link-volver">Volver al inicio</a>
		</section>
	);
};

export default ErrorPage;