import { createRoot } from 'react-dom/client';
import React from 'react';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import global_en from './translations/en/global.json';
import global_es from './translations/es/global.json';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const detectLanguageByGeolocation = () => {
	return new Promise((resolve) => {
		if (navigator.geolocation) { 
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					fetch(
						`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
					)
						.then((response) => response.json())
						.then((data) => {
							const country = data.countryCode;
							const spanishSpeakingCountries = [
								'ES', 'MX', 'AR', 'CO', 'PE', 'VE', 'CL', 'EC', 'GT', 'CU', 
								'BO', 'DO', 'HN', 'PY', 'SV', 'NI', 'CR', 'PA', 'UY'
							];
							
							if (spanishSpeakingCountries.includes(country)) {
								resolve('es');
							} else {
								resolve('en');
							}
						});
				},
				() => {
					resolve('en'); 
				}
			);
		} else {
			resolve('en');
		}
	});
};

const initializeLanguage = async () => {
	const storedLang = localStorage.getItem('language');
	if (storedLang) {
		return storedLang;
	} else {
		const detectedLang = await detectLanguageByGeolocation();
		localStorage.setItem('language', detectedLang);
		return detectedLang;
	}
};

i18next.init({
	interpolation: { escapeValue: false },
	lng: 'en',
	resources: {
		en: {
			global: global_en,
		},
		es: {
			global: global_es,
		},
	},
});

root.render(
	<Router>
		<I18nextProvider i18n={i18next}>
			<App />
		</I18nextProvider>
	</Router>
);

initializeLanguage().then((lng) => {
	if (lng !== 'en') {
		i18next.changeLanguage(lng);
		root.render(
			<Router>
				<I18nextProvider i18n={i18next}>
					<App />
				</I18nextProvider>
			</Router>
		);
	}
});
