import React, { useEffect, Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';	
import Toolbar from '@mui/material/Toolbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import logo from './logo.svg';
import { theme } from './theme';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Lang from '../proceso-image/lang.png';



const styles = {
	toolbarMargin: {
		...theme.mixins.toolbar,
		marginBottom: '3em',
		[theme.breakpoints.down('md')]: {
			marginBottom: '2em',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '1.25em',
		},
	},
	logo: {
		height: '8em',
		[theme.breakpoints.down('md')]: {
			height: '7em',
		},
		[theme.breakpoints.down('xs')]: {
			height: '5.5em',
		},
	},
	logoContainer: {
		...theme.mixins.toolbar,
		marginLeft: '8rem',
		[theme.breakpoints.down('md')]: {
			marginLeft: '2em',
		},
    
		minWidth: '16rem',
		width: '16rem',
		padding: 0,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	tabs: {
		marginLeft: 'auto',
		marginRight: '5%',
		'& .MuiButtonBase-root.MuiTab-root': {
			fontSize: '1.7rem',
			textTransform: 'none',
			fontWeight: 400,
			fontStyle: 'normal',
			lineHeight: 'normal',
			color: '#2D2D2B',
			marginLeft: '2.3rem'
		},
		'& .Mui-selected': {
			backgroundColor: 'white',
			color: '#2D2D2B',
			opacity: 1,
			borderRadius: 2,
		},
		'& .MuiTouchRipple-root': {
			color: 'transparent',
		},
		'& .MuiTabs-indicator': {
			backgroundColor: '#81358A',
			height: '0.3rem',
			position: 'absolute',
			top: '0rem'
		},
	},
	tab: {
		minHeight: '10rem',
		height: '10rem',
		color: '#2D2D2B',
		minWidth: 10,
		marginLeft: '25px',
	},
	hamburgerMenuIcon: {
		color: '#81358A',
		height: '50px',
		width: '50px',
	},
	menuIconContainer: {
		marginLeft: 'auto',
		'&:hover': {
			opacity: 1,
		},
	},
	appbar: {
		backgroundColor: 'white',
		zIndex: 999,
	},
	listitem: {
		width: '150px',
		color: '#81358A',
		fontSize: '1.7rem',
		textTransform: 'none',
		fontWeight: 400,
		fontStyle: 'normal',
		lineHeight: 'normal',
	},
	select: {
		height: '5rem',
		marginTop: '2.7rem',
		textTransform: 'none',
		fontWeight: 400,
		fontStyle: 'normal',
		lineHeight: 'normal',
		color: '#2D2D2B',
		'.MuiOutlinedInput-notchedOutline': { border: 0 },
		'&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
		},
		'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 0,
		},
		fontSize: '1.4rem',
		'&. MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root': {
			fontSize: '1.4rem'
		},
		'& .MuiSvgIcon-root': {
			color: '#81358A',
			fontSize: '3.5rem',
			marginTop: '-0.3rem',
			marginRight: '-1.5rem'
		}
	},
	selectdrop: {
		textTransform: 'none',
		fontWeight: 400,
		fontStyle: 'normal',
		lineHeight: 'normal',
		color: '#2D2D2B',
		height: '3rem',
		fontSize: '1.4rem',

	},
	selectdropmobile: {
		textTransform: 'none',
		fontWeight: 400,
		fontStyle: 'normal',
		lineHeight: 'normal',
		height: '4rem',
		marginLeft: '0.3rem',
		'.MuiOutlinedInput-notchedOutline': { border: 0 },
		'&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			border: 0,
		},
		'&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: 0,
		},
		'&. MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters MuiMenuItem-root MuiMenuItem-gutters css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root': {
			fontSize: '1.4rem'
		},
		width: '150px',
		color: '#81358A',
		fontSize: '1.7rem',
		'& .MuiSvgIcon-root': {
			color: '#81358A',
			fontSize: '2.5rem',
			marginTop: '-0.3rem',
			marginRight: '-1.5rem'
		}
	}
};

const DesktopNavigation = () => {
	const [value, setValue] = useState(0);
	const location = useLocation();
	const [selectedLang, setSelectedLang] = useState(localStorage.getItem('language') || 'en');

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, [location]);

	useEffect(() => {
		setSelectedLang(localStorage.getItem('language') || 'en');
	}, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const navigate = useNavigate();

	const navigateHome = () => {
		navigate('/');
	};

	const navigateContact = () => {
		navigate('/contact');
	};

	const navigateService = () => {
		navigate('/services');
	};
	/*
	const navigateNewsletter = () => {
		navigate('/newsletter');
	};
	*/

	const [t, i18n] = useTranslation('global');

	const handleLangChange = (event) => {
		const lang = event.target.value;
		i18n.changeLanguage(lang);
		localStorage.setItem('language', lang);
		setSelectedLang(lang);
	};
	
	return (
		<Tabs
			value={value}
			onChange={handleChange}
			aria-label="nav tabs example"
			sx={styles.tabs}
		>
			<Tab sx={styles.tab} label={t('header.home')} onClick={navigateHome} />
			<Tab sx={styles.tab} label={t('header.process')} onClick={navigateService} />
			{/*	
		<Tab sx={styles.tab} label="Newsletter" onClick={navigateNewsletter} /> 
		*/}
			<Tab style={{borderRadius: '0.7rem',border: '1px solid #81358A',background: 'rgba(129, 53, 138, 0.10)',margin: '2.6rem 2rem 3rem 2rem',color: '#81358A',fontWeight: '500'}} label={t('header.contact-us')} onClick={navigateContact} />
			<img style={{ width: '3rem', height: '3rem', marginTop: '3rem', marginLeft: '1rem'}} src={Lang} alt=''/>
			<FormControl>
				<Select
					sx={styles.select}
					value={selectedLang}
					onChange={handleLangChange}
					MenuProps={{ disableScrollLock: true }}
				>
					<MenuItem sx={styles.selectdrop} value={'es'}>ES</MenuItem>
					<MenuItem sx={styles.selectdrop} value={'en'}>EN</MenuItem>
				</Select>
			</FormControl>
		</Tabs>
	);
};

const MobileNavigation = () => {
	const [openDrawer, setOpenDrawer] = React.useState(false);
	const [selectedLang, setSelectedLang] = useState(localStorage.getItem('language') || 'en');

	useEffect(() => {
		setSelectedLang(localStorage.getItem('language') || 'en');
	}, []);

	const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
	const navigate = useNavigate();

	const navigateHome = () => {
		navigate('/');
	};

	const navigateContact = () => {
		navigate('/contact');
	};

	const navigateService = () => {
		navigate('/services');
	};
	/*
	const navigateNewsletter = () => {
		navigate('/newsletter');
	};
	*/
	const [t, i18n] = useTranslation('global');

	const handleLangChange = (event) => {
		const lang = event.target.value;
		i18n.changeLanguage(lang);
		localStorage.setItem('language', lang);
		setSelectedLang(lang);
	};

	return (
		<React.Fragment>
			<SwipeableDrawer
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
				open={openDrawer}
				onClose={() => setOpenDrawer(false)}
				onOpen={() => setOpenDrawer(true)}
			>
				<Box sx={styles.toolbarMargin} />
				<Paper >
					<List disablePadding>
						<ListItem
							divider
							button
							onClick={() => {
								navigateHome();
								setOpenDrawer(false);
							}}>
							<ListItemText sx={styles.listitem} disableTypography>{t('header.home')}</ListItemText>
						</ListItem>
						<ListItem
							divider
							button
							onClick={() => {
								navigateService();
								setOpenDrawer(false);
							}}
						>
							<ListItemText sx={styles.listitem} disableTypography>{t('header.process')}</ListItemText>
						</ListItem>
						{/*
						<ListItem
							divider
							button
							onClick={() => {
								navigateNewsletter();
								setOpenDrawer(false);
							}}>
							<ListItemText sx={styles.listitem} disableTypography>Newsletter</ListItemText>
						
						</ListItem>
							*/}
						<ListItem
							divider
							button
							onClick={() => {
								navigateContact();
								setOpenDrawer(false);
							}}
						>
							<ListItemText sx={styles.listitem} disableTypography>{t('header.contact-us')}</ListItemText>
						</ListItem>
						<FormControl>
							<Select
								sx={styles.selectdropmobile}
								value={selectedLang}
								onChange={handleLangChange}
								displayEmpty
								autoWidth='True'
								MenuProps={{ disableScrollLock: true }}
							>
								<MenuItem sx={styles.selectdropmobile} value={'es'}>ES</MenuItem>
								<MenuItem sx={styles.selectdropmobile} value={'en'}>EN</MenuItem>
							</Select>
						</FormControl>
					</List>
				</Paper>
			</SwipeableDrawer>
			<IconButton
				sx={styles.menuIconContainer}
				onClick={() => setOpenDrawer(!openDrawer)}
				disableRipple
			>
				<MenuIcon sx={styles.hamburgerMenuIcon} />
			</IconButton>
		</React.Fragment>
	);
};

const Header = () => {
	const isMobileMode = useMediaQuery(theme.breakpoints.down('lg'));

	return (
		<Fragment>
			<AppBar
				position="fixed"
				sx={styles.appbar}
				elevation={9}
			>
				<Toolbar disableGutters={true}>
					<Box sx={styles.logoContainer}>
						<Box alt="company logo" />
						<img src={logo} alt="Kiuvi" />
					</Box>
					{isMobileMode ? <MobileNavigation /> : <DesktopNavigation />}
				</Toolbar>
			</AppBar>
		</Fragment>
	);
};

export default Header;
