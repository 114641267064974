import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactPage = () => {
	const [t] = useTranslation('global');
	const form = useRef();
	const buttonRef = useRef();

	const sendEmail = (e) => {
		e.preventDefault();
        
		// Añadir clase de animación
		const button = buttonRef.current;
		button.classList.add('animate');
        
		// Remover la clase de animación después de que la animación haya terminado
		setTimeout(() => {
			button.classList.remove('animate');
		}, 300); // Debe coincidir con la duración de la animación en el CSS

		emailjs
			.sendForm('service_yd6tyg9', 'template_4ckkuna', form.current, {
				publicKey: '9N1dlRK_j9HLuCnp-',
			})
			.then(
				() => {
					toast.success('¡Correo enviado con éxito!', {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						style: {
							fontSize: '18px',
							fontFamily: 'Roboto, sans-serif'
						}
					});
					form.current.reset();
				},
				(error) => {
					toast.error('Error al enviar el correo: ' + error.text, {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						style: {
							fontSize: '18px',
							fontFamily: 'Roboto, sans-serif'
						}
					});
				},
			);
	};

	return (
		<section id="contactanos" className="card-hero">
			<div className="card">
				<img src="./imagenes/imagenformulario.jpg" alt="" className="card-img"/>
				<form className="card-form" ref={form} onSubmit={sendEmail}>
					<p className="form-title">{t('card.form-tittle')}</p>
					<div className="card-form-text">
						<label className="form-text">{t('card.form-name')}<input className="input-form" type="text" name="name" required/></label>
						<label className="form-text">{t('card.form-mail')}<input className="input-form" type="email" name="email" required/></label>
					</div>
					<p className="form-text">{t('card.form-place')}</p>
					<textarea className="textarea-form" name="message" required></textarea>
					<button
						className="submit-button"
						type="submit"
						value="Enviar mensaje"
						ref={buttonRef}
					>
						{t('card.form-button')}
					</button>
					<input type="hidden" name="_next" value="https://www.kiuviservices.com.ar/"></input>
				</form>
			</div>
			<ToastContainer />
		</section>
	);
};

export default ContactPage;
